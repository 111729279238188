import {defineStore} from 'pinia'
import moment from 'moment'
import {useTabStore} from "@/stores/tab-module"

export const useFilterStore = defineStore('filters', {
    state: () => {
        return {
            // general
            loading: true,
            initialSelection: true,

            // date related settings
            dateFrom: moment().startOf('year'),
            dateTo: moment().endOf('month'),
            dateFormatApi: 'YYYY-MM-DD',

            // filters
            teamSelection: [],
            teamOptions: [],
            employeeSelection: [],
            employeeOptions: [],
            quotationChanceOfSuccess: 70,
            accountLeadSelection: [],
            accountLeadOptions: [],
        }
    },
    getters: {
        getDateRange(state) {
            if (typeof state.dateFrom === 'string') {
                state.dateFrom = moment(state.dateFrom)
            }
            if (state.dateFrom === null) {
                state.dateFrom = moment().startOf('year')
            }
            if (typeof state.dateTo === 'string') {
                state.dateTo = moment(state.dateTo)
            }
            if (state.dateTo === null) {
                state.dateTo = moment().endOf('month')
            }
            return [
                {
                    month: state.dateFrom.format('M')-1,
                    year: state.dateFrom.format('YYYY'),
                }, {
                    month: state.dateTo.format('M')-1,
                    year: state.dateTo.format('YYYY'),
                }
            ]
        },
        getDateFromInApiFormat(state) {
            if (typeof state.dateFrom === 'string') {
                state.dateFrom = moment(state.dateFrom)
            }
            if (state.dateFrom === null) {
                state.dateFrom = moment()
            }
            return state.dateFrom.format(state.dateFormatApi)
        },
        getDateToInApiFormat(state) {
            if (typeof state.dateTo === 'string') {
                state.dateTo = moment(state.dateTo)
            }
            if (state.dateTo === null) {
                state.dateTo = moment()
            }
            return state.dateTo.endOf('month').format(state.dateFormatApi)
        },
    },
    actions: {
        changeDateRange(dateFrom, dateTo) {
            const tabStore = useTabStore()
            this.dateFrom = dateFrom
            this.dateTo = dateTo

            tabStore.loadTabData()
        },
        updateTeams(teamSelection) {
            const tabStore = useTabStore()
            this.teamSelection = teamSelection
            tabStore.loadTabData()
        },
        updateEmployees(employeeSelection) {
            const tabStore = useTabStore()
            this.teamSelection = [];
            this.employeeSelection = employeeSelection
            tabStore.loadTabData()
        },
        updateAccountLeadSelection(value) {
            const tabStore = useTabStore()
            this.accountLeadSelection = value
            tabStore.loadTabData()
        },
        updateQuotationChanceOfSuccess(value) {
            const tabStore = useTabStore()
            this.quotationChanceOfSuccess = value
            tabStore.loadTabData()
        }
    },
    persist: true,
})
