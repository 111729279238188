import Axios from "axios";
import {useAuthStore} from "@/stores/auth-module";


class ApiService {
    async get(path, params = {}, headers = {}, useAuth = true) {

        if (useAuth) {
            const authStore = useAuthStore()
            headers = {
                ...headers,
                ...{
                    Authorization: `Bearer ${authStore.getAccessToken}`
                }
            }

        }

        try {
            return await Axios.get(`${process.env.VUE_APP_API_URL}/api/spa/${path}`, {
                params,
                headers
            })
        } catch (error) {
            const authStore = useAuthStore()
            if(error.response.status === 401) {
                authStore.logout()
            }
        }
    }
}

export default new ApiService()
