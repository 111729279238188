<template>
  <div class="overflow-hidden rounded-lg bg-white px-2 py-3 shadow sm:p-3 relative" :class="clickable ? 'hover:bg-indigo-50 cursor-pointer': ''">
    <dt class="truncate text-sm font-medium text-gray-500">{{ title }} <span class="text-xs">{{ subtitle }}</span></dt>
    <dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      {{ number }}
    </dd>
    <slot/>
    <div class="absolute top-0 right-3" v-if="clickable">
      <p class="text-blue-600/100 text-lg">&#187;</p>
    </div>
  </div>

</template>

<script>
export default {
  name: 'DataCard',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    number: {
      type: String
    },
    clickable: {
      default: false,
      type: Boolean
    }
  }
}
</script>
