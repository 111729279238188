<template>
  <VueDatePicker
      v-model="dateRange"
      month-picker
      range
      auto-apply
      @closed="updateDates2"
      :disabled="filterStore.loading"
      ref="datepicker"
      class="mb-2"
  />
  <div class="max-w-xs flex flex-col rounded-md shadow-sm">
    <button @click="presetLastMonth" type="button"
            class="-mt-px py-2 px-3 inline-flex justify-center items-center gap-2 border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
      Vorige maand
    </button>
    <button @click="presetThisMonth" type="button"
            class="-mt-px py-2 px-3 inline-flex justify-center items-center gap-2 border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
      Deze maand
    </button>
    <button @click="presetNextMonth" type="button"
            class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-t-md border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
      Volgende maand
    </button>
    <button @click="presetYtd" type="button"
            class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-t-md border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
      Dit jaar (YTD)
    </button>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {useFilterStore} from "@/stores/filter-module"
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment'
import _ from "lodash";

const datepicker = ref(null);
const filterStore = useFilterStore()

const dateRange = ref(
    filterStore.getDateRange
);

const updateDates2 = function () {
  if (dateRange.value[0] !== null && dateRange.value[1] !== null) {
    filterStore.changeDateRange(
        moment({
          month: dateRange.value[0].month,
          year: dateRange.value[0].year
        }),
        moment({
          month: dateRange.value[1].month,
          year: dateRange.value[1].year
        })
    )
  }
}

const presetYtd = function () {
  filterStore.changeDateRange(
      moment().startOf('year'),
      moment(),
  )
}

const presetLastMonth = function () {
  filterStore.changeDateRange(
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
  )
}

const presetThisMonth = function () {
  filterStore.changeDateRange(
      moment().startOf('month'),
      moment().endOf('month'),
  )
}

const presetNextMonth = function () {
  filterStore.changeDateRange(
      moment().add(1, 'month').startOf('month'),
      moment().add(1, 'month').endOf('month'),
  )
}

watch(() => filterStore.dateFrom, _.debounce(function () {
  dateRange.value = filterStore.getDateRange
}), 500);

watch(() => filterStore.dateTo, _.debounce(function () {
  dateRange.value = filterStore.getDateRange
}), 500);

</script>
