import {defineStore} from 'pinia'
import ApiService from "@/services/ApiService";

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            accessToken: null,
            error: undefined,
            loggedIn: false,
        }
    },
    getters: {
        hasAccessToken(state) {
            return state.accessToken !== null;
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getError(state) {
            return state.error
        }
    },
    actions: {
        async socialLogin(query) {
            return await ApiService.get(`auth/google/callback`, query, {}, false)
                .then(data => {
                    this.accessToken = data.data.access_token
                    this.loggedIn = true
                }).catch(e => {
                    this.error = e.response.data.message
                    throw e;
                });
        },
        mutateError(error) {
            this.error = error
        },
        logout() {
            this.loggedIn = false
            this.accessToken = null
            this.router.push('/')
        }
    },
    persist: true,
})
