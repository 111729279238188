<template>
  <div
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <div class="load-wrapper rounded-full mb-4">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
    </div>
    <h2 class="text-center text-white text-xl font-semibold">Laden..</h2>
    <p class="w-1/3 text-center text-white">Bezig met authenticeren...</p>
  </div>
</template>

<script setup>
import {onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useAuthStore} from "@/stores/auth-module";

onMounted(async () => {
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore()

  try {
    // Make sure the page is visible for at least 1.5 seconds
    await Promise.all([
      authStore.socialLogin(route.query),
      new Promise(resolve => setTimeout(resolve, 1500)),
    ]);
  } catch (e) {
    await router.push('/');
    return;
  }

  await router.push('/dashboard?tab=writtenHours');
})

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

</style>
