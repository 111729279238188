<template>
  <div v-if="!tabStore.loading && tabStore.reports.kpis && tabStore.reports.charts">
    <div v-if="!tabStore.reports.kpis.isForecast">
      <TitleDivider :title="`Geschreven uren ${tabStore.reports.kpis.isPartiallyForecast ? '(t/m vandaag)' : ''}`"/>
      <dl class="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-4 mb-5">
        <DataCard title="Declarabel"
                  :number="pastPercentageBillableHours"
                  clickable
                  @click="projectModalStore.openModal('billableWrittenHours', 'Totaal aan geschreven declarabele uren')"
        />
        <DataCard title="Niet declarabel"
                  :number="pastPercentageNonBillableHours"
                  clickable
                  @click="projectModalStore.openModal('nonBillableWrittenHours', 'Totaal aan geschreven niet declarabele uren')"
        />
        <DataCard title="Niet geschreven" :number="pastPercentageNotWrittenHours"/>
        <DataCard title="Capaciteit" :number="`${tabStore.reports.kpis.past.capacity} uur`"/>
      </dl>
    </div>
    <div v-if="tabStore.reports.kpis.isForecast || tabStore.reports.kpis.isPartiallyForecast">
      <TitleDivider :title="`Planning ${tabStore.reports.kpis.isPartiallyForecast ? '(vanaf morgen)' : ''}`"/>
      <h4 class="text-md font-medium leading-6 text-gray-900"></h4>
      <dl class="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-4 mb-5">
        <DataCard title="Declarabel"
                  :number="plannedPercentageBillableHours"
                  clickable
                  @click="projectModalStore.openModal('billablePlannedHours', 'Totaal aan geplande declarabele uren')"
        />
        <DataCard title="Niet declarabel"
                  :number="plannedPercentageNonBillableHours"
                  clickable
                  @click="projectModalStore.openModal('notBillablePlannedHours', 'Totaal aan geplande niet declarabele uren')"
        />
        <DataCard title="Vrije capaciteit" :number="percentageNonPlannedHours"/>
        <DataCard title="Capaciteit"
                  :number="`${tabStore.reports.kpis.planned.capacity} uur`"
        />
      </dl>
    </div>
    <dl class="mt-3">
      <TitleDivider :title="`${tabStore.reports.kpis.isPartiallyForecast ? 'Gecombineerde grafiek' : (tabStore.reports.kpis.isForecast ? 'Planning' : 'Geschreven uren')} (per ${tabStore.reports.charts.period})`"/>
      <ApexChart :options="newCapacityChartOptions" :series="tabStore.reports.charts.capacity" height="300px" title=""/>
    </dl>
    <div v-if="!tabStore.reports.kpis.isForecast">
      <TitleDivider :title="`Verdeling declarabel geschreven uren ${tabStore.reports.kpis.isPartiallyForecast ? '(t/m vandaag)' : ''}`"/>
      <dl class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 mb-5">
        <DataCard :title="`Projecten / ${tabStore.reports.kpis.past.billableProjectHours} uur`"
                  subtitle="(van declarabel)"
                  :number="pastPercentageProjectHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableProjectWrittenHours', 'Uren die geschreven zijn op reguliere opdrachten (geen SLA of Offerte)')"
        />
        <DataCard :title="`SLA's / ${tabStore.reports.kpis.past.billableServiceHours} uur`"
                  subtitle="(van declarabel)"
                  :number="pastPercentageServiceHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableServiceWrittenHours', 'Uren die geschreven zijn op SLA overeenkomsten')"
        />
        <DataCard :title="`Offertes / ${tabStore.reports.kpis.past.billableOfferHours} uur`"
                  subtitle="(van declarabel)"
                  :number="pastPercentageOfferHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableOfferWrittenHours', 'Uren die geschreven zijn op Offertes')"
        />
      </dl>
    </div>
    <div v-if="tabStore.reports.kpis.isForecast || tabStore.reports.kpis.isPartiallyForecast">
      <TitleDivider :title="`Verdeling declarabele planning ${tabStore.reports.kpis.isPartiallyForecast ? '(vanaf morgen)' : ''}`"/>
      <dl class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 mb-5">
        <DataCard :title="`Projecten / ${tabStore.reports.kpis.planned.billableProjectHours} uur`"
                  subtitle="(van declarabel)"
                  :number="plannedPercentageProjectHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableProjectPlannedHours', 'Geplande Project uren (declarabel)')"
        />
        <DataCard :title="`SLA's / ${tabStore.reports.kpis.planned.billableServiceHours} uur`"
                  subtitle="(van declarabel)"
                  :number="plannedPercentageServiceHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableServicePlannedHours', 'Geplande SLA uren (declarabel)')"
        />
        <DataCard :title="`Offertes / ${tabStore.reports.kpis.planned.billableOfferHours} uur`"
                  subtitle="(van declarabel)"
                  :number="plannedPercentageOfferHoursOfBillable"
                  clickable
                  @click="projectModalStore.openModal('billableOfferPlannedHours', 'Geplande Offerte uren (declarabel)')"
        />
      </dl>
    </div>
    <dl class="mt-3">
      <TitleDivider :title="`Verdeling declarabele uren (per ${tabStore.reports.charts.period})`"/>
      <ApexChart :options="billableChartOptions" :series="tabStore.reports.charts.billable" height="300px" title=""/>
    </dl>
    <div v-if="!tabStore.reports.kpis.isForecast">
      <TitleDivider title="Uurtarieven"/>
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 mb-5">
                <DataCard title="Gemiddeld projectuurtarief"
                          :number="`&euro; ${tabStore.reports.kpis.past.hourlyProjectRate.price}`"
                >
                <slot>
                  <button
                      type="button"
                      @click="openModal"
                      class="bg-opacity-20 mt-1 text-xs text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    Hoe berekenen we dit uurtarief?
                  </button>
                </slot>
                </DataCard>
        <DataCard title="Dekkingsgraad berekening"
                  :number="formatNumberToPercentage(tabStore.reports.kpis.past.hourlyProjectRate.usedBillableHoursForCalculation / (tabStore.reports.kpis.past.billableHours - tabStore.reports.kpis.past.billableServiceHours - tabStore.reports.kpis.past.billableOfferHours))"
        >
          <slot>
            <p class="mt-4 text-xs text-black">Percentage van de uren, waaraan we een uurtarief konden toekennen.</p>
          </slot>
        </DataCard>
      </dl>
    </div>
      <dl v-if="tabStore.reports.kpis.isForecast">
        <TitleDivider :title="`Verdeling per werknemer (geplande uren ${tabStore.reports.kpis.isPartiallyForecast ? 'vanaf morgen' : ''})`"/>
        <ApexChart :options="options" :series="series" :height="`${(tabStore.reports.employeeData.length * 35) + 80 }px`" title=""/>
      </dl>
  </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
              >
                Berekening gemiddeld projectuurtarief
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500 py-2">
                  Per project (= opdracht in Gripp) berekenen we een gemiddeld uurtarief.
                  Dit berekenen we door de omzet van een opdracht (cumulatief van alle verstuurde facturen die aan de
                  opdracht gekoppeld zijn)
                  te delen door alle declarabel geschreven uren op de opdracht.
                </p>
                <p class="text-sm text-gray-500 py-2">
                  Dit gemiddelde project uurtarief kennen we toe aan dit project. Vervolgens gebruiken we deze waarde om
                  te kijken welke waarde elk geschreven uur heeft in de gekozen periode.
                  Zo berekenen we het gemiddelde projectuurtarief voor een periode.
                </p>
                <p class="text-sm text-gray-500 py-2">
                  <strong>Worden declarabel geschreven SLA uren en omzet uit SLA's meegenomen in deze
                    berekening?</strong><br>
                  Nee! We kijken naar projecten/opdrachten. Uren geschreven op SLA's nemen we niet mee. We de omzet op
                  die opdrachten dus ook niet.
                </p>
                <p class="text-sm text-gray-500 py-2">
                  <strong>Kunnen we van elk declarabel geschreven uur de waarde bepalen?</strong><br>
                  Goeie vraag! Helaas niet. Er zijn uren waaraan we, door een gekozen administratieve opzet, geen waarde
                  kunnen toekennen.
                  De uren waarvoor we geen uurtarief hebben kunnen berekenen nemen we ook niet mee in dit gemiddelde.
                  Die sluiten we uit.
                </p>
                <p class="text-sm text-gray-500 py-2">
                  <strong>Is het dan zo dat niet gefactureerde omzet niet wordt meegenomen?</strong><br>
                  Dat klopt! Het is ook erg belangrijk om regelmatig te factureren.
                </p>
                <p class="text-sm text-gray-500 py-2">
                  <strong>Als onder een opdracht iets anders dan uren wordt gefactureerd, nemen we dat dan mee?</strong><br>
                  Ja, we nemen alle omzet mee. Als we zouden filteren op omzet type 'uren', missen we teveel omzet. Soms
                  is er bijvoorbeeld sprake van een 'aanbetaling', dat is dan niet type 'uren'.
                  Maar dat is dan wel degelijk een betaling voor uren.
                </p>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {computed, ref} from 'vue'
import ApexChart from "@/components/ApexChart"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
import DataCard from "@/components/DataCard";
import {useProjectOverviewStore} from "@/stores/projects-modal-module";
import {useTabStore} from "@/stores/tab-module";
import TitleDivider from "@/components/TitleDivider";

const formatNumberToPercentage = function (number) {
  if (isNaN(number)) {
    return '0%'
  }
  return (number * 100).toFixed(2) + '%'
}

const projectModalStore = useProjectOverviewStore()

const tabStore = useTabStore()

const pastPercentageBillableHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.past.billableHours / tabStore.reports.kpis.past.capacity)
  }
  return 0
});

const plannedPercentageBillableHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.planned.billableHours / tabStore.reports.kpis.planned.capacity)
  }
  return 0
});

const pastPercentageNonBillableHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.past.nonBillableHours / tabStore.reports.kpis.past.capacity)
  }
  return 0
});

const plannedPercentageNonBillableHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.planned.nonBillableHours / tabStore.reports.kpis.planned.capacity)
  }
  return 0
});

const pastPercentageOfferHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.past.billableOfferHours / tabStore.reports.kpis.past.billableHours)
  }
  return 0
});

const pastPercentageProjectHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage((tabStore.reports.kpis.past.billableHours - tabStore.reports.kpis.past.billableServiceHours - tabStore.reports.kpis.past.billableOfferHours) / tabStore.reports.kpis.past.billableHours)
  }
  return 0
});

const pastPercentageServiceHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.past.billableServiceHours / tabStore.reports.kpis.past.billableHours)
  }
  return 0
});

const plannedPercentageOfferHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.planned.billableOfferHours / tabStore.reports.kpis.planned.billableHours)
  }
  return 0
});

const plannedPercentageProjectHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage((tabStore.reports.kpis.planned.billableHours - tabStore.reports.kpis.planned.billableServiceHours - tabStore.reports.kpis.planned.billableOfferHours) / tabStore.reports.kpis.planned.billableHours)
  }
  return 0
});

const plannedPercentageServiceHoursOfBillable = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage(tabStore.reports.kpis.planned.billableServiceHours / tabStore.reports.kpis.planned.billableHours)
  }
  return 0
});

const percentageNonPlannedHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage((tabStore.reports.kpis.planned.capacity - tabStore.reports.kpis.planned.plannedHours) / tabStore.reports.kpis.planned.capacity)
  }
  return 0
});

const pastPercentageNotWrittenHours = computed(function () {
  if (typeof tabStore.reports.kpis !== 'undefined') {
    return formatNumberToPercentage((tabStore.reports.kpis.past.capacity - tabStore.reports.kpis.past.writtenHours) / tabStore.reports.kpis.past.capacity)
  }
  return 0
});

const isOpen = ref(false)

function closeModal() {
  isOpen.value = false
}

function openModal() {
  isOpen.value = true
}

const newCapacityChartOptions = {
  chart: {
    height: 350,
    type: 'line',
    stacked: false,
    zoom: {enabled: false}
  },
  stroke: {
    width: [2, 2, 4, 3],
    curve: ['smooth', 'smooth', 'smooth', 'smooth'],
  },
  plotOptions: {
    bar: {
      columnWidth: '60%'
    },
  },
  yaxis: {
    min: 0,
  },
  fill: {
    opacity: [0.1, 0.25, 0.5],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: "vertical",
      opacityFrom: 0.2,
      opacityTo: 1,
      stops: [0, 100, 100, 100]
    }
  },
  forecastDataPoints: {
    count: tabStore.reports.charts.forecastdatapoints
  },
  labels: tabStore.reports.charts.labels,
  xaxis: {
    categories: tabStore.reports.charts.labels,
    group: {
      style: {
        fontSize: '10px',
        fontWeight: 700
      },
      groups: tabStore.reports.charts.groups,
    }
  },
  colors: ['#94a3b8', '#fed7aa', '#12B981', '#f87171'],
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (y) {
        if (typeof y !== "undefined") {
          return y.toFixed(0) + " uur";
        }
        return y;

      }
    }
  },
  legend: {
    position: 'bottom',
    offsetX: 30,
    offsetY: 10
  },
}

const billableChartOptions = {
  colors: ['#12B981', '#f87171', '#fed7aa'],
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: '100%'
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }],
  xaxis: {
    categories: tabStore.reports.charts.labels,
    group: {
      style: {
        fontSize: '10px',
        fontWeight: 700
      },
      groups: tabStore.reports.charts.groups,
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: 'bottom',
    offsetX: 30,
    offsetY: 10
  },
}

const seriesDataHelper = tabStore.reports.employeeData.sort((a,b) => a.billableProjectHours/a.workableHours - b.billableProjectHours/b.workableHours);

const series = [{
  name: 'Declarabel',
  data: seriesDataHelper.map(value => value.billableProjectHours)
}, {
  name: 'Niet declarabel',
  data: seriesDataHelper.map(value => value.nonBillableHours)
}, {
  name: 'Vrije capaciteit',
  data: seriesDataHelper.map(value => value.nonPlannedHours)
}];

const options = {
  chart: {
    type: 'bar',
    stacked: true,
    stackType: "100%",
    events: {
      click: function(event, chartContext, config) {
        console.log(event, chartContext, config)
      }
    },
  },
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  stroke: {
    show: false,
  },
  xaxis: {
    categories: tabStore.reports.employeeData.map(value => value.name),
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " uur"
      },
    }
  },
  colors:['#12B981', '#fb923c', '#94a3b8'],
  fill: {
    colors:['#12B981', '#fb923c', '#94a3b8'],
  }
}

</script>
