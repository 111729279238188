<template>
  <div
      class="flex flex-col items-start justify-center w-64"
  >
    <check-box
        v-for="option in options"
        :checked="value.includes(option.id)"
        @update:checked="check(option.id, $event)"
        :fieldId="option.name"
        :label="option.name"
        :key="option"
        :disabled="disabled"
    />
  </div>
</template>

<script>
import Checkbox from "./InputCheckbox";

export default {
  emits: ["update:value"],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
            Object.keys(option).includes("name")
        );
        const hasIdKey = value.every((option) =>
            Object.keys(option).includes("id")
        );
        return hasNameKey && hasIdKey;
      },
    },
  },
  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit("update:value", updatedValue);
    };

    return {
      check,
    };
  },
  components: {
    "check-box": Checkbox,
  },
};
</script>
