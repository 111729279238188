import {defineStore} from 'pinia'
import ApiService from "@/services/ApiService"
import {useFilterStore} from "@/stores/filter-module"
import moment from 'moment'

export const useTabStore = defineStore('tabs', {
    state: () => {
        return {
            reports: [],
            loading: true
        }
    },
    getters: {},
    actions: {
        loadTabData(tab = this.router.currentRoute._value.query.tab) {
            // make sure it's never undefined
            if (tab === undefined) {
                tab = 'writtenHours'
            }
            const filterStore = useFilterStore()
            this.loading = true
            filterStore.$patch({
                loading: true
            })
            return ApiService.get(`data/${tab}`, {
                'dateFrom': filterStore.getDateFromInApiFormat,
                'dateTo': filterStore.getDateToInApiFormat,
                'teams': filterStore.teamSelection,
                'employees': filterStore.employeeSelection.map(employee => employee.id),
                'initial': filterStore.initialState,
                'ageBillableHour': filterStore.billableHoursAgeInDays,
                'includeArchived': Boolean(filterStore.includeArchivedProjects),
                'years': filterStore.yearSelection,
                'chance': filterStore.quotationChanceOfSuccess,
                'accountLeads': filterStore.accountLeadSelection.map(aL => aL.id)
            }).then((response) => {
                this.reports = response.data.reports
                filterStore.$patch({
                    dateFrom: moment(response.data.filters.dateFrom),
                    dateTo: moment(response.data.filters.dateTo),
                    loading: false,
                    initialSelection: false,
                    teamOptions: response.data.filters.teamOptions ?? filterStore.teamOptions,
                    teamSelection: response.data.filters.teams ? response.data.filters.teams.map(str => {
                        return Number(str);
                    }) : filterStore.teamSelection,
                    employeeOptions: response.data.filters.employeeOptions ?? filterStore.employeeOptions,
                    employeeSelection: response.data.filters.employees ?? filterStore.employeeSelection,
                    billableHoursAgeInDays: response.data.filters.ageBillableHour ?? filterStore.billableHoursAgeInDays,
                    includeArchivedProjects: response.data.filters.includeArchived ?? filterStore.includeArchivedProjects,
                    yearOptions: response.data.filters.yearOptions ?? filterStore.yearOptions,
                    yearSelection: response.data.filters.years ? response.data.filters.years.map(str => {
                        return Number(str)
                    })  : filterStore.yearSelection,
                    accountLeadOptions: response.data.filters.accountLeadOptions ?? filterStore.accountLeadSelection,
                })
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.loading = false
                filterStore.$patch({
                    loading: false
                })
            })
        }
    }
})
