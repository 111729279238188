<template>
  <div v-if="!tabStore.loading">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Offertes</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 mb-5">
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" v-if="1 === 1">
        <dt class="truncate text-sm font-medium text-gray-500">Aantal openstaande offertes</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ tabStore.reports.quotationCount }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" v-if="1 === 1">
        <dt class="truncate text-sm font-medium text-gray-500">In euro's</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">&euro; {{ tabStore.reports.openRevenue }}
        </dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" v-if="1 === 1">
        <dt class="truncate text-sm font-medium text-gray-500">In uren</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ tabStore.reports.openHours }} uur
        </dd>
      </div>
    </dl>
    <div v-if="[...tabStore.reports.tableData].filter(quotation => quotation.available_hours > 0).length > 0">
      <h3 class="text-lg font-medium leading-4 text-gray-900 mb-3">Offertes (met planbaar werk)</h3>
      <div class="text-sm mb-3">Hieronder een overzicht van de offertes die een projectregel met het type 'uren' hebben.
        Bij een slagingskans van 70% of meer is het de bedoeling dat deze offerte wordt ingepland. Overal waar dat niet
        het geval is zien we een waarschuwingsteken,
      </div>
    </div>
    <div role="list" class="grid grid-cols-1 gap-4 mb-10">
      <div class="overflow-hidden bg-white shadow"
           v-for="quotation in [...tabStore.reports.tableData].filter(quotation => quotation.available_hours > 0)"
           :key="quotation.gripp_id">
        <Disclosure>
          <DisclosureButton class="p-3 grid grid-cols-12 rounded-tr-lg rounded-tl-lg border-2" :class="[
                  quotation.chance === 100 ? 'border-2 border-lime-500' : '',
                  quotation.chance >= 70 && quotation.chance < 100 ? 'border-2 border-lime-300' : '',
                  quotation.chance >= 50 && quotation.chance < 70 ? 'border-2 border-lime-200' : '',
                  quotation.chance < 50 ? 'border-2 border-lime-100' : '',
              ]" style="width: 100%">
            <div class="col-span-1">
              <ExclamationTriangleIcon
                  v-if="checkPlannedHours(quotation)" class="h-6 w-6 mt-2 text-red-500 inline"/>
              <ArrowsPointingInIcon
                  v-if="!checkPlannedHours(quotation)" class="h-6 w-6 mt-2 text-gray-700 inline"/>
            </div>
            <div class="col-span-8">
              <h3 class="text-base font-medium leading-4 text-gray-900 text-left">{{ quotation.name }} &rarr;
                {{ quotation.chance }}%</h3>
              <p class="text-left">{{ quotation.company }}</p>
            </div>
            <div class="col-span-3">
              <div class="mb-1 text-base font-medium text-gray-600">{{ quotation.planned_hours ?? 0 }} /
                {{ quotation.available_hours ?? 0 }} uur
              </div>
              <div class="w-full bg-gray-200 rounded-full h-2.5">
                <div class="bg-blue-600 h-2.5 rounded-full"
                     :style="`width:${plannedHoursPercentage(quotation)}%`"></div>
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel class="text-gray-500">
            <div class="border-t border-gray-200 " :class="[
                  quotation.chance === 100 ? 'border-2 border-lime-300' : '',
                  quotation.chance >= 70 && quotation.chance < 100 ? 'border-2 border-yellow-300' : '',
                  quotation.chance >= 50 && quotation.chance < 70 ? 'border-2 border-orange-300' : '',
                  quotation.chance < 50 ? 'border-2 border-orange-300' : '',
              ]">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Gripp <img :src="grippLogo" class="ml-3 header-icon">
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><a :href="jsonGrippLink"><a
                      :href="`https://acato.gripp.com/public/index.php?u=96054#`+jsonGrippLink(quotation.gripp_id)"
                      class="text-blue-700" target="_blank">#{{ quotation.gripp_id }} - Bekijk in Gripp</a></a></dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Waarde</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">€ {{ quotation.total_excl_vat }},-</dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Fase</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.phase }}</dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Geplande uren</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><span>{{
                      quotation.planned_hours ?? 0
                    }} / {{ quotation.available_hours ?? 0 }} uur <ExclamationTriangleIcon
                        v-if="checkPlannedHours(quotation)" class="h-5 w-5 text-gray-700 inline"/></span>
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Geschreven uren</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.written_hours ?? 0 }} uur
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3 bg-gray-100">
                  <dt class="text-sm font-medium text-gray-500">Account Lead</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.account_lead }}</dd>
                </div>
              </dl>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
    <div v-if="[...tabStore.reports.tableData].filter(quotation => quotation.available_hours === null).length > 0">
      <h3 class="text-lg font-medium leading-4 text-gray-900 mb-3 mt-2">Offertes (overig)</h3>
      <div class="text-sm mb-3">Voor onderstaande offertes geldt dat er geen projectonderdelen met het type 'uren'
        gevonden zijn. We gaan er dan vanuit, dat dit niet ingepland hoeft te worden.
      </div>
    </div>
    <div role="list" class="grid grid-cols-1 gap-4 mb-5">
      <div class="overflow-hidden bg-white shadow"
           v-for="quotation in [...tabStore.reports.tableData].filter(quotation => quotation.available_hours === null)"
           :key="quotation.gripp_id">
        <Disclosure>
          <DisclosureButton class="p-3 grid grid-cols-12 rounded-tr-lg rounded-tl-lg" :class="[
                  quotation.chance === 100 ? 'border-2 border-lime-500' : '',
                  quotation.chance >= 70 && quotation.chance < 100 ? 'border-2 border-lime-300' : '',
                  quotation.chance >= 50 && quotation.chance < 70 ? 'border-2 border-lime-200' : '',
                  quotation.chance < 50 ? 'border-2 border-lime-100' : '',
              ]" style="width: 100%">
            <div class="col-span-1">
              <ExclamationTriangleIcon
                  v-if="checkPlannedHours(quotation)" class="h-6 w-6 mt-2 text-red-500 inline"/>
              <ArrowsPointingInIcon
                  v-if="!checkPlannedHours(quotation)" class="h-6 w-6 mt-2 text-gray-700 inline"/>
            </div>
            <div class="col-span-8">
              <h3 class="text-base font-medium leading-4 text-gray-900 text-left">{{ quotation.name }} &rarr;
                {{ quotation.chance }}%</h3>
              <p class="text-left">{{ quotation.company }}</p>
            </div>
            <div class="col-span-3">
              <div class="mb-1 text-base font-medium text-gray-600">{{ quotation.planned_hours ?? 0 }} /
                {{ quotation.available_hours ?? 0 }} uur
              </div>
              <div class="w-full bg-gray-200 rounded-full h-2.5">
                <div class="bg-blue-600 h-2.5 rounded-full"
                     :style="`width:${plannedHoursPercentage(quotation)}%`"></div>
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel class="text-gray-500">
            <div class="border-t border-gray-200 " :class="[
                  quotation.chance === 100 ? 'border-2 border-lime-300' : '',
                  quotation.chance >= 70 && quotation.chance < 100 ? 'border-2 border-yellow-300' : '',
                  quotation.chance >= 50 && quotation.chance < 70 ? 'border-2 border-orange-300' : '',
                  quotation.chance < 50 ? 'border-2 border-orange-300' : '',
              ]">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Gripp <img :src="grippLogo" class="ml-3 header-icon">
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><a :href="jsonGrippLink"><a
                      :href="`https://acato.gripp.com/public/index.php?u=96054#`+jsonGrippLink(quotation.gripp_id)"
                      class="text-blue-700" target="_blank">#{{ quotation.gripp_id }} - Bekijk in Gripp</a></a></dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Waarde</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">€ {{ quotation.total_excl_vat }},-</dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Fase</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.phase }}</dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Geplande uren</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><span>{{
                      quotation.planned_hours ?? 0
                    }} / {{ quotation.available_hours ?? 0 }} uur <ExclamationTriangleIcon
                        v-if="checkPlannedHours(quotation)" class="h-5 w-5 text-red-500 inline"/></span>
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                  <dt class="text-sm font-medium text-gray-500">Geschreven uren</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.written_hours ?? 0 }} uur
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3 bg-gray-100">
                  <dt class="text-sm font-medium text-gray-500">Account Lead</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ quotation.account_lead }}</dd>
                </div>
              </dl>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
    <GrippTimeline/>
  </div>
</template>

<script setup>
import {ref, watch} from "vue";
import GrippTimeline from "@/components/Modals/GrippTimeline";
import {useTabStore} from "@/stores/tab-module";
import _ from "lodash";
import {useFilterStore} from "@/stores/filter-module";
import {ExclamationTriangleIcon, ArrowsPointingInIcon} from '@heroicons/vue/24/outline'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

const filterStore = useFilterStore();
const grippLogo = require('@/assets/gripp.webp');
const tabStore = useTabStore()
const chanceOfSuccess = ref(filterStore.quotationChanceOfSuccess)

watch(() => chanceOfSuccess.value, _.debounce(function () {
  filterStore.updateQuotationChanceOfSuccess(chanceOfSuccess.value)
}, 1000));

const jsonGrippLink = function (id) {
  return btoa('{"action":"viewSubpage","actionparams":{"sn_":{"n6069n":{"recordid":"' + id + '","o_":"view"}},"re_":"n6073n"}}');
}

const checkPlannedHours = function (quotation) {
  if (quotation.chance >= 70) {
    if ((quotation.planned_hours / quotation.available_hours) < 0.8) {
      return true;
    }
  }
  return false
}

const plannedHoursPercentage = function (quotation) {
  if (quotation.available_hours > 0) {
    let percentage = (quotation.planned_hours / quotation.available_hours) * 100;
    return percentage > 100 ? 100 : percentage;
  }
  return 0;
}

</script>

<style>
.customize-header {
  display: flex;
  justify-items: center;
  align-items: center;
}

.header-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

table tr td {
  font-size: 14px;
}

</style>

