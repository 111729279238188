<template>
  <div class="bg-white dark:bg-gray-900">
    <div class="flex justify-center h-screen">
      <div class="hidden bg-cover lg:block lg:w-2/3"
           v-bind:style="{ 'background-image': 'url( ' + backgroundImage + ')' }">
        <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
          <div>
            <h2 class="text-4xl font-bold text-white">Rapport</h2>

            <p class="max-w-xl mt-3 text-gray-300">Een rapport is een verslaglegging van een activiteit, gebeurtenis of
              toestand. Dit verslag wordt, al dan niet na voorafgaand onderzoek, door één of meer rapporteur(s) gedaan
              aan een doelgroep, die daartoe doorgaans, maar niet noodzakelijkerwijs, de opdracht heeft gegeven of de
              wens uitgesproken.</p>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1">
          <div class="text-center">
            <h2 class="text-4xl font-bold text-center text-gray-700 dark:text-white">Reporter</h2>

            <p class="mt-3 text-gray-500 dark:text-gray-300">Gebruik je Google account om in te loggen</p>
          </div>

          <div class="mt-8 text-center">
            <form>
              <div class="mt-6">
                <a v-bind:href="loginUrl"
                   class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
                  <svg class="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab"
                       data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                    <path fill="currentColor"
                          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                  </svg>
                  Sign in with Google
                </a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="authStore.getError !== undefined"
       class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <div class="icon-wrapper rounded-full mb-4 h-14 w-14 bg-white border-2 border-red-500">
      <svg x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <path
            d="M28.36,25.763V31h-0.72v-5.237c0-5.419-3.679-10.076-8.946-11.324  c-0.15-0.036-0.261-0.163-0.275-0.316c-0.015-0.154,0.071-0.3,0.212-0.363c2.34-1.041,3.851-3.365,3.851-5.919  c0-3.573-2.907-6.48-6.48-6.48c-3.574,0-6.481,2.907-6.481,6.48c0,2.555,1.512,4.878,3.851,5.919  c0.141,0.063,0.226,0.209,0.212,0.363c-0.014,0.153-0.125,0.281-0.275,0.316c-5.27,1.248-8.949,5.905-8.949,11.324V31H3.64v-5.237  c0-5.444,3.497-10.164,8.611-11.779c-2.123-1.296-3.452-3.618-3.452-6.144c0-3.97,3.23-7.2,7.201-7.2s7.201,3.23,7.201,7.2  c0,2.525-1.329,4.847-3.452,6.144C24.863,15.599,28.36,20.318,28.36,25.763z M18.745,21.745L16,24.49l-2.746-2.745l-0.509,0.51  L15.49,25l-2.745,2.745l0.509,0.51L16,25.51l2.745,2.745l0.51-0.51L16.51,25l2.745-2.745L18.745,21.745z"/>
      </svg>
    </div>
    <h2 class="text-center text-red-200 text-xl font-semibold">Oeps!</h2>
    <p class="w-1/3 text-center text-white">{{ error }}</p>
    <button @click="handleErrorAcknowledge"
            class="mt-4 text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
      Allright...
    </button>
  </div>
</template>

<script setup>
import {computed, onMounted} from 'vue'
import {useAuthStore} from "@/stores/auth-module";
import router from "@/router";

const backgrounds = [
  require('@/assets/backgrounds/1.webp'),
  require('@/assets/backgrounds/2.webp'),
  require('@/assets/backgrounds/3.webp'),
];

const authStore = useAuthStore()
const backgroundImage = computed(() => backgrounds[Math.floor(Math.random() * backgrounds.length)]);
const loginUrl = computed(() => `${process.env.VUE_APP_API_URL}/api/auth/google/redirect`);

function handleErrorAcknowledge() {
  authStore.mutateError(undefined)
}

onMounted(() => {
  const authStore = useAuthStore()
  if (authStore.loggedIn) {
    router.push('/dashboard');
  }
})

</script>

<style scoped>

.icon-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

</style>
