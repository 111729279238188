<template>
  <div class="relative flex items-start mb-1">
    <div class="flex h-5 items-center">
      <input
          @input="(event) => $emit('update:checked', event.target.checked)"
          type="checkbox"
          :disabled="disabled"
          :checked="checked"
          :id="fieldId"
          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </div>
    <div class="text-sm">
      <label
          :for="fieldId"
          class="flex flex-row text-gray-800 select-none"
          :class="!disabled ? 'cursor-pointer' : ''"
      >
        <i
            class="fa mr-2"
            :class="{
        'fa-check-square text-blue-600': checked,
        'fa-square text-gray-500': !checked,
      }"
        ></i>
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>
