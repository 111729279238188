import {defineStore} from 'pinia'
import ApiService from "@/services/ApiService";

export const useProjectModalTimelineStore = defineStore('grippTimeline', {
    state: () => {
        return {
            messages: [],
            modalOpen: false,
            loading: false
        }
    },
    getters: {
        loadingState(state) {
            return state.loading;
        },
        getMessages(state) {
            return state.messages
        },
        modalOpenState(state) {
            return state.modalOpen
        }
    },
    actions: {
        openModal(projectGrippId) {
            this.messages = []
            this.loading = true
            this.modalOpen = true
            return ApiService.get(`data/grippTimeline?id=${projectGrippId}`).then((response) => {
                this.messages = response.data
                this.loading = false
            }).catch(e => {
                const message = e.response?.status === 401 ? "Authenticatie mislukt" : e.message;
                console.log(message)
                throw e;
            });
        },
        closeModal() {
            this.modalOpen = false
        }
    },
})
