import {defineStore} from 'pinia'
import ApiService from "@/services/ApiService"

export const useBillableHoursStore = defineStore('tabs', {
    state: () => {
        return {
            // data
            reports: [],
            loading: true,

            // filters
            projectManager: null,
            projectManagerOptions: [],
            billableHoursAgeInDays: 30,
            includeArchivedProjects: 0,
            yearOptions: [],
            yearSelection: [],
        }
    },
    getters: {},
    actions: {
        getProjectManagerOptions() {
            this.loading = true
            return ApiService.get(`options/projectManagers`).then((response) => {
                this.projectManagerOptions = response.data.options
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.loading = false
            })
        },
        loadData() {
            this.loading = true
            return ApiService.get(`data/billableHours`, {
                'ageBillableHour': this.billableHoursAgeInDays,
                'includeArchived': Boolean(this.includeArchivedProjects),
                'years': this.yearSelection,
                'projectManager': this.projectManager
            }).then((response) => {
                this.reports = response.data.reports
                this.yearSelection = response.data.filters.years
                this.projectManager = response.data.filters.projectManager
                this.includeArchivedProjects = response.data.filters.includeArchivedProjects
                this.billableHoursAgeInDays = response.data.filters.ageBillableHour
                this.yearOptions = response.data.filters.yearOptions
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.loading = false
            })
        },
        updateBillableHoursAgeInDays(value) {
            this.billableHoursAgeInDays = value
            this.loadData()
        },
        updateIncludeArchivedProjects(value) {
            this.includeArchivedProjects = value
            this.loadData()
        },
        updateYearSelection(value) {
            this.yearSelection = value
            this.loadData()
        },
        updateProjectManager(value) {
            this.projectManager = value
            this.loadData()
        },
    }
})
