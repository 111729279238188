<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <div class="mt-5 pb-20 min-h-full">
            <div class="grid grid-cols-12 gap-3 wrapper">
              <div class="col-span-12 bg-white shadow">
                <vue-progress-bar/>
                <div class="mt-10 mb-10 flex justify-center" v-if="loading">
                  <img :src="loadingImage">
                </div>
                <div class="p-3" v-if="!loading">

                  <h3 class="text-lg font-medium leading-6 text-gray-900 m-4">Planning komende 6 weken</h3>
                  <table class="min-w-full divide-y divide-x divide-gray-300" v-if="!loading">
                    <thead>
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Team
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          v-for="(key) in weekstartData.planning.columns" :key="key">
                        {{ key }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="team in weekstartData.planning.data" :key="team.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {{ team.name }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          :class="getClassColor(team.billableHours[key]/team.capacity[key])"
                          v-for="(key) in weekstartData.planning.columns" :key="key">
                        <span
                            class="text-base font-medium">{{ formatNumberToPercentage(team.billableHours[key] / team.capacity[key]) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <h3 class="text-lg font-medium leading-6 text-gray-900 m-4 mt-6">Resultaat</h3>
                  <table class="min-w-full divide-y divide-x divide-gray-300">
                    <thead>
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Team
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        YTD
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          v-for="(key) in weekstartData.results.columns" :key="key">
                        {{ key }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="team in weekstartData.results.data" :key="team.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {{ team.name }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          :class="getClassColor(team.billableHoursTotal / team.capacityTotal)">
                        <span
                            class="text-base font-medium">{{ formatNumberToPercentage(team.billableHoursTotal / team.capacityTotal) }}</span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          :class="getClassColor(team.billableHours[key]/team.capacity[key])"
                          v-for="(key) in weekstartData.results.columns" :key="key">
                        <span
                            class="text-base font-medium">{{ formatNumberToPercentage(team.billableHours[key] / team.capacity[key]) }}</span>
                      </td>
                    </tr>
                    <tr class="bg-gray-100">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        <span class="font-weight-bold">Acato</span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          :class="getClassColor(weekstartData.results.dataAll.billableHoursTotal / weekstartData.results.dataAll.capacityTotal)">
                        <span
                            class="text-base font-medium">{{ formatNumberToPercentage(weekstartData.results.dataAll.billableHoursTotal / weekstartData.results.dataAll.capacityTotal) }}</span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          :class="getClassColor(weekstartData.results.dataAll.billableHours[key]/weekstartData.results.dataAll.capacity[key])"
                          v-for="(key) in weekstartData.results.columns" :key="key">
                        <span
                            class="text-base font-medium">{{ formatNumberToPercentage(weekstartData.results.dataAll.billableHours[key] / weekstartData.results.dataAll.capacity[key]) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {getCurrentInstance, onActivated, onMounted, ref, watch} from 'vue'
import {useRouter} from "vue-router";
import ProjectModal from "@/components/Modals/ProjectModal";
import {useAuthStore} from "@/stores/auth-module";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";
import ApiService from "@/services/ApiService"

const loadingImage = require('@/assets/loading-animation.svg');
const internalInstance = getCurrentInstance();

const router = useRouter();
const weekstartData = ref();
const loading = ref(true);

onActivated(() => {
  const authStore = useAuthStore()
  if (!authStore.loggedIn) {
    router.push('/');
  }
})

onMounted(() => {
  ApiService.get('data/weekstart').then((response) => {
    weekstartData.value = response.data
    loading.value = false;
  })
})

const getClassColor = function (number) {
  if (number < 0.08) {
    return '';
  }
  if (number < 0.2) {
    return 'bg-red-300';
  }
  if (number < 0.3) {
    return 'bg-red-200';
  }
  if (number < 0.4) {
    return 'bg-orange-200';
  }
  if (number < 0.5) {
    return 'bg-orange-100';
  }
  if (number < 0.6) {
    return 'bg-yellow-100';
  }
  if (number < 0.7) {
    return 'bg-green-100';
  }

  return 'bg-green-200';
}

watch(loading, () => {
  if (!loading.value) {
    internalInstance.appContext.config.globalProperties.$Progress.finish();
  } else {
    internalInstance.appContext.config.globalProperties.$Progress.start();
  }
})

const formatNumberToPercentage = function (number) {
  if (isNaN(number)) {
    return '0%'
  }
  return (number * 100).toFixed(0) + '%'
}

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}


</style>
