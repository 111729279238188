import {defineStore} from 'pinia'
import ApiService from '@/services/ApiService';
import {useFilterStore} from '@/stores/filter-module';

export const useProjectOverviewStore = defineStore('projectsModal', {
    state: () => {
        return {
            modalOpen: false,
            modalTitle: 'Projecten gekozen periode',
            loading: false,
            dataType: 'billableHours',
            tableData: []
        }
    },
    getters: {
        loadingState(state) {
            return state.loading;
        },
        getTableData(state) {
            return state.tableData
        },
        modalOpenState(state) {
            return state.modalOpen
        },
        getModalTitle(state) {
            return state.modalTitle
        }
    },
    actions: {
        openModal(dataType, title) {
            const filterStore = useFilterStore()
            this.dataType = dataType
            this.modalTitle = title
            this.tableData = []
            this.loading = true
            this.modalOpen = true
            return ApiService.get(`data/specific?type=${this.dataType}`, {
                'dateFrom': filterStore.getDateFromInApiFormat,
                'dateTo': filterStore.getDateToInApiFormat,
                'teams': filterStore.teamSelection,
                'employees': filterStore.employeeSelection.map(employee => employee.id)
            }).then(response => {
                this.tableData = response.data
                this.loading = false
            }).catch(e => {
                const message = e.response?.status === 401 ? "Authenticatie mislukt" : e.message;
                console.log(message)
                throw e;
            });
        },
        closeModal() {
            this.modalOpen = false
        }
    },
})
