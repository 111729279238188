<template>
  <div v-if="router.currentRoute._value.query.tab === 'writtenHours' || router.currentRoute._value.query.tab === 'plannedHours'" class="p-4">
    <fieldset class="pb-5">
      <legend class="block text-sm font-medium text-gray-900 mb-3">Kies periode</legend>
      <DateRangeSelector :disabled="filterStore.loading"/>
    </fieldset>
    <fieldset class="mb-4">
      <div class="text-sm font-medium text-gray-900 mb-2 flex flex-row justify-between">
        <div>Teams</div>
        <div class="font-light cursor-pointer" @click="selectAllTeams" v-if="filterStore.teamSelection.length !== filterStore.teamOptions.length && !filterStore.loading">
          + alle teams
        </div>
        <div class="font-light cursor-pointer" @click="deselectAllTeams" v-if="filterStore.teamSelection.length === filterStore.teamOptions.length && !filterStore.loading">
          - leeg alles
        </div>
      </div>
      <InputMultipleCheckbox @update:value="updateTeams" v-model:value="teamSelection" :disabled="filterStore.loading"
                             :options="filterStore.teamOptions"/>
    </fieldset>
    <fieldset>
      <legend class="block text-sm font-medium text-gray-900 mb-2">Medewerkers</legend>
      <VueMultiselect
          v-model="filterStore.employeeSelection"
          :options="filterStore.employeeOptions"
          @close="updateEmployees"
          :multiple="true"
          :close-on-select="false"
          placeholder="Kies een medewerker"
          label="name"
          track-by="id"
          :disabled="filterStore.loading"
      >
        <template v-slot:selection="values">
          <span class="multiselect__single" v-if="values.values.length" v-show="!values.isOpen">{{ values.values.length }} medewerker(s) geselecteerd</span>
        </template>
      </VueMultiselect>
    </fieldset>
  </div>
  <div v-if="router.currentRoute._value.query.tab === 'quotationTab'" class="p-4">
    <fieldset>
      <label for="default-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Slagingskans</label>
      <div class="flex items-center">
        <span class="pr-4">>&nbsp;{{chanceOfSuccess}}%</span>
        <input id="steps-range" v-model="chanceOfSuccess" :disabled="filterStore.loading" type="range" min="0" max="100" step="5" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
      </div>
    </fieldset>
    <fieldset class="mt-5">
      <legend class="block text-sm font-medium text-gray-900 mb-2">Account Lead</legend>
      <VueMultiselect
          v-model="accountLeadSelection"
          :options="filterStore.accountLeadOptions"
          :multiple="true"
          :close-on-select="false"
          placeholder="Kies een Account Lead"
          label="name"
          track-by="id"
          :disabled="filterStore.loading"
      />
    </fieldset>


  </div>
</template>

<script setup>
import '@vuepic/vue-datepicker/dist/main.css'
import {ref, watch} from 'vue'
import _ from 'lodash'
import InputMultipleCheckbox from "@/components/InputMultipleCheckbox";
import DateRangeSelector from "@/components/DateRangeSelector"
import {useFilterStore} from "@/stores/filter-module";
import {useRouter} from "vue-router/dist/vue-router";
import VueMultiselect from 'vue-multiselect'

// stores
const filterStore = useFilterStore()

// selected
const teamSelection = ref(filterStore.teamSelection);
const chanceOfSuccess = ref(filterStore.quotationChanceOfSuccess)
const accountLeadSelection = ref(filterStore.accountLeadSelection);
const router = useRouter();

const updateEmployees = function (value) {
  filterStore.updateEmployees(value)
}

const updateTeams = _.debounce(function (value) {
  filterStore.updateTeams(value)
}, 1000)

const updateAccountLeads = _.debounce(function (value) {
  filterStore.updateAccountLeadSelection(value)
}, 1000)

const selectAllTeams = function () {
  filterStore.updateTeams(filterStore.teamOptions.map(obj => obj.id))
}

const deselectAllTeams = function () {
  filterStore.updateTeams([])
}

// watchers
watch(() => filterStore.teamSelection, function() {
  teamSelection.value = filterStore.teamSelection
});

watch(() => filterStore.accountLeadSelection, function() {
  accountLeadSelection.value = filterStore.accountLeadSelection
});

watch(() => chanceOfSuccess.value, _.debounce(function() {
  filterStore.updateQuotationChanceOfSuccess(chanceOfSuccess.value)
},1000));

watch(() => accountLeadSelection.value, _.debounce(function() {
  updateAccountLeads(accountLeadSelection.value)
},1000));


</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect-tag {
  font-size: 0.5rem !important;
}
</style>
